export const commonSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  image:
    "https://www.panhandlemetals.com/static/9129e0fa60db2069c93b53d8013cc5d8/700b1/logo.webp",
  url: "https://www.panhandlemetals.com/",
  sameAs: [
    "https://maps.app.goo.gl/qn5AUMUa1fwnmwrU9",
    "https://www.facebook.com/PanhandleMetals/",
    "https://www.instagram.com/panhandlemetals/",
  ],
  logo: "https://www.panhandlemetals.com/static/9129e0fa60db2069c93b53d8013cc5d8/700b1/logo.webp",
  name: "Panhandle Metals",
  description:
    "Panhandle Metals - One of the leading metal building manufacturers offering a wide range of metal buildings at affordable prices. Call our building experts to discuss your metal building requirements now!",
  email: "info@panhandlemetals.com",
  telephone: "(806) 731-4815",
  address: {
    "@type": "PostalAddress",
    streetAddress: "12721 I-27 South",
    addressLocality: "Amarillo",
    addressRegion: "TX",
    postalCode: "79119",
    addressCountry: {
      "@type": "Country",
      name: "US",
    },
  },
};

// export const metalBuildingsSchema = {
//   "@context": "https://schema.org",
//   "@type": "BreadcrumbList",
//   itemListElement: [
//     {
//       "@type": "ListItem",
//       position: 1,
//       name: "Home",
//       item: "https://www.panhandlemetals.com/",
//     },
//     {
//       "@type": "ListItem",
//       position: 2,
//       name: "Texas Metal Buildings",
//       item: "https://www.panhandlemetals.com/tx-metal-buildings/",
//     },
//   ],
// };

// export const getBreadcrumbSchema = (pageData) => {

//   return {
//     "@context": "https://schema.org",
//     "@type": "BreadcrumbList",
//     itemListElement: [
//       {
//         "@type": "ListItem",
//         position: 1,
//         name: "Home",
//         item: "https://www.panhandlemetals.com/",
//       },
//       {
//         "@type": "ListItem",
//         position: 2,
//         name: pageData.name,
//         item: `https://www.panhandlemetals.com${pageData.url}/`,
//       },
//     ],
//   };
// };

export const getProductSchema = (productData) => {
  const getProductImageUrl = () => {
    if (productData.image.gatsbyImageData) {
      const its = productData.image.gatsbyImageData.images.fallback.src.split("?");
      return `https:${its[0]}`;
    }
    return "https://www.panhandlemetals.com/static/9129e0fa60db2069c93b53d8013cc5d8/700b1/logo.webp"
  }

  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: productData.name,
    image: getProductImageUrl(),
    offers: {
      "@type": "Offer",
      url: `https://www.panhandlemetals.com${productData.url}`,
      priceCurrency: "USD",
      price: productData.price,
      availability: "http://schema.org/InStock",
      itemCondition: "http://schema.org/NewCondition",
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: 0,
          currency: "USD",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: {
            "@type": "Country",
            name: "US",
          },
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          handlingTime: {
            "@type": "QuantitativeValue",
            minValue: 0,
            maxValue: 1,
            unitCode: "DAY",
          },
          transitTime: {
            "@type": "QuantitativeValue",
            minValue: 1,
            maxValue: 4,
            unitCode: "DAY",
          },
        },
      },
    },
    manufacturer: {
      "@type": "Organization",
      name: "Panhandle Metals",
      url: "https://www.panhandlemetals.com/",
      logo: "https://www.panhandlemetals.com/static/9129e0fa60db2069c93b53d8013cc5d8/700b1/logo.webp",
      telephone: "(806) 731-4815",
      email: "info@panhandlemetals.com",
      address: {
        "@type": "PostalAddress",
        streetAddress: "12721 I-27 South",
        addressLocality: "Amarillo",
        addressRegion: "TX",
        postalCode: "79119",
        addressCountry: {
          "@type": "Country",
          name: "US",
        },
      },
    },
  };
};

