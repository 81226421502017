import styled from "styled-components";

export const ProductCard = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    margin-bottom: 30px;
  }
`;

export const ProductFigure = styled.div`
  position: relative;
  margin-bottom: 10px;
  .gatsby-image-wrapper {
    border-radius: 15px;
    img {
      transform-origin: 65% 75%;
      transition: transform 1s, filter 0.5s ease-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &:hover {
    .media-hover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const ProductSku = styled.div`
  color: #7d7d7d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;
export const ProductCaption = styled.div`
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  padding: 10px 20px;
  width: calc(100% - 20px);
  @media (min-width: 1440px) {
    padding: 10px 30px;
  }
`;
export const ProductTitle = styled.div`
  position: relative;
  font-weight: 700;
  color: #131413;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 1600px) {
    font-size: 20px;
    line-height: 30px;
  }
  > a {
    color: #131413;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ProductBody = styled.div`
  margin: 0;
`;

export const ProductInnerBody = styled.div`
  position: relative;
  border-radius: 15px;
  background: linear-gradient(to bottom, #eaeff7 0%, #f9fbff 100%);
  text-align: center;
  margin: 0 auto;
  padding: 20px 10px;
  @media (min-width: 992px) {
    padding: 20px 15px;
  }
  @media (min-width: 1440px) {
    padding: 20px;
  }
`;

export const ProductPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -5px 5px -5px;
  font-family: "Montserrat", sans-serif;
  span {
    padding: 0 5px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 26px;
    }
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  strong {
    font-weight: 700;
    padding: 0 5px;
    color: #c20000;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 36px;
    }
    @media (min-width: 1200px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;
export const HelpText = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #6a717a;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
  @media (min-width: 576px) {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;
export const ProductToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
  @media (min-width: 1440px) {
    margin: 0 -10px;
  }
`;

export const ProductItemTool = styled.div`
  position: relative;
  padding: 0 5px;
  @media (min-width: 1440px) {
    padding: 0 10px;
  }
  .btn {
    text-transform: none;
    .icon {
      display: none;
      @media (min-width: 576px) {
        display: flex;
      }
      > svg {
        width: 16px;
        @media (min-width: 1440px) {
          width: auto;
        }
      }
    }
    .text {
      padding: 6px 12px !important;
      @media (min-width: 1600px) {
        font-size: 16px !important;
        line-height: 30px !important;
        padding: 8px 20px !important;
      }
    }
  }
  .btn-call {
    .btn {
      box-shadow: none;
    }
  }
`;

export const RtoInfo = styled.div`
  font-family: "Montserrat", sans-serif;
  position: relative;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #a8a6ab;
  font-weight: 500;
  margin: 0 -6px 15px -6px;
  justify-content: center;
  @media (min-width: 1200px) {
    margin: 0 -12px 20px -12px;
  }
  .rto-item {
    position: relative;
    + .rto-item {
      margin-top: 5px;
      @media (min-width: 1200px) {
        margin-top: 0;
        &::after {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          top: 50%;
          width: 1px;
          height: 22px;
          background-color: #fff;
          transform: translateY(-50%);
        }
      }
    }
  }
`;

export const RtoItemInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 5px;
  @media (min-width: 1200px) {
    padding: 0 10px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    + .text {
      margin-left: 10px;
    }
  }
  .text {
    font-size: 12px;
    line-height: 20px;
    @media (min-width: 1200px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;
