import React, { useState } from "react";
import {
  Section,
  Container,
  SectionTitle,
  ExploreMore,
} from "../../components/Section";
// import TopSellingCarousel from "../../components/TopSellingCarousel";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ProductCard from "../../components/ProductCard";
import styled from "styled-components";

const GridMasonery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: space-evenly;
  align-items: center;
`;
const GridItemMasonery = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .card {
    .card-title {
      > span {
        @media (min-width: 1440px) {
          font-size: 18px;
        }
      }
    }
  }
`;

const TopSellingSection = ({ data }) => {
  const [showLimit, setShowLimit] = useState(6);

  return (
    <Section
      ept="120px"
      epb="120px"
      xpt="80px"
      xpb="80px"
      pt="60px"
      pb="60px"
      bgColor="#fff"
      className="section-selling"
    >
      <Container>
        <SectionTitle textAlign="center">
          Our Top Selling <span>TX Buildings</span>
        </SectionTitle>
      </Container>
      {/* <TopSellingCarousel data={data} /> */}
      <Container>
        <GridMasonery>
          {data.map(
            (item, i) =>
              i < showLimit && (
                <GridItemMasonery className="item" key={i}>
                  <ProductCard sku={item.node.skuNumber} data={item.node} />
                </GridItemMasonery>
              )
          )}
        </GridMasonery>
        {data.length > showLimit && (
          <ExploreMore mt="30px">
            <button
              type="button"
              aria-label="button"
              onClick={() => setShowLimit(showLimit + 6)}
            >
              <PrimaryButton text="Load More" />
            </button>
          </ExploreMore>
        )}
      </Container>
      {/* <ExploreMore mt="0">
        <Link to="/gallery">
          <PrimaryButton text="See More Building Styles" />
        </Link>
      </ExploreMore> */}
    </Section>
  );
};
export default TopSellingSection;
