import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  ProductCard,
  ProductFigure,
  ProductSku,
  ProductTitle,
  ProductBody,
  ProductInnerBody,
  ProductCaption,
  ProductPrice,
  HelpText,
  ProductToolbar,
  ProductItemTool,
} from "../ProductStyle";
import PrimaryButton from "../Button/PrimaryButton";
import DefaultButton from "../Button/DefaultButton";

import { PhoneIcon } from "../Icons";
import { Link } from "gatsby";

const ProductCards = ({ sku, data }) => {
  return (
    <ProductCard className="product">
      <ProductFigure className="product-figure">
        <GatsbyImage image={data.image.gatsbyImageData} alt={data.name} />
        <ProductCaption className="product-caption">
          <ProductTitle className="product-title">
            <Link to={data.url}>{data.name}</Link>
          </ProductTitle>
          <ProductSku className="product-sku">SKU : PANM#{sku}</ProductSku>
        </ProductCaption>
      </ProductFigure>
      <ProductBody className="product-body">
        <ProductInnerBody>
          <ProductPrice className="product-price">
            <span>Starting Price :</span>
            {!!data.price && (
              <strong>${data.price.toLocaleString("en-US")}*</strong>
            )}
          </ProductPrice>
          <HelpText className="product-helptext">
            (Prices vary by location, & customization)
          </HelpText>
          <ProductToolbar className="product-toolbar">
            <ProductItemTool className="product-item-tool">
              <a
                href="tel:8067314815"
                aria-label="Phone Number"
                className="btn-call"
              >
                <PrimaryButton
                  text="(806) 731-4815"
                  iconAfter={<PhoneIcon />}
                  size="md"
                />
              </a>
            </ProductItemTool>
            <ProductItemTool className="product-item-tool">
              <Link to={data.url} className="btn-view">
                <DefaultButton text="View Details" size="md" />
              </Link>
            </ProductItemTool>
          </ProductToolbar>
        </ProductInnerBody>
      </ProductBody>
    </ProductCard>
  );
};

export default ProductCards;
