import React, { useEffect, useContext } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import { LineArrowRightIcon } from "../Icons";
import { Context } from "../context";

const QuotePopUpButton = ({ btntext }) => {
  const { popupContext, setPopupContext } = useContext(Context);

  const HandleModalOpen = () => {
    setPopupContext(true);
  };
  useEffect(() => {
    if (popupContext === true) {
      document.querySelector("body").classList.add("modal-open");
    } else {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [popupContext, setPopupContext]);

  return (
    <>
      <button type="button" aria-label="button" onClick={HandleModalOpen}>
        <PrimaryButton
          iconspace="icon-space"
          text={btntext}
          iconAfter={<LineArrowRightIcon />}
        />
      </button>
    </>
  );
};

export default QuotePopUpButton;
